import React, {Component} from 'react';
import Policy from './Policy';
import {Text, ScrollView} from '@applane/react-core-components';

class TermsConditions extends Component {
  render() {
    //New File Created (Policy.js) for All the Policies Where Now we only Pass Name As Parameter by Kawal - on 18-05-2021
    return <Policy name="Terms & Conditions" />;
  }
}

export default TermsConditions;
