// Web Landing Page to download our app  by Nafees  on 23/04/21 
// Zeplin Reference - https://app.zeplin.io/project/5ea6e88c949d1ebe27eb63db/screen/608042883fc4541cb3cb105e
import {View, Text, ScrollView} from '@applane/react-core-components';
import React, {Component} from 'react';

const playStoreUrl =
  'https://play.google.com/store/apps/details?id=com.beyond.fimbre';
const appStoreUrl = 'https://apps.apple.com/us/app/id1522246014';

export default class LandingScreen extends Component {
  render() {
    return (
      <ScrollView>
        <View
          style={{
            backgroundColor: '#f9f3f3',
            paddingLeft: 60,
            height: '100vh',
            width: '100vb',
          }}>
            <img
              src={'/fimbre.svg'}
              style={{alignSelf: 'flex-start', marginTop: 42}}
            />
          <View style={{flexDirection: 'row',}}>
            <View>
              <Text
                style={{
                  color: '#303339',
                  fontSize: 36,
                  fontFamily: 'Montserrat-SemiBold',
                  marginTop: 92,
                  marginBottom: 24,
                }}>
                Discover trustable <br /> suggestions from like-
                <br /> minded people
              </Text>
              <Text
                style={{
                  color: '#6f7580',
                  fontSize: 28,
                  fontFamily: 'Montserrat-Regular',
                }}>
                Discover groups relevant to <br /> your interests and ask <br />{' '}
                questions to get genuine <br />
                suggestions for product,
                <br /> movies, lifehacks, etc
              </Text>
              <View style={{flexDirection: 'row', marginTop: 48}}>
                <a href={playStoreUrl}>
                  <img src={'/play_store_badge.svg'} style={{marginRight: 24}} />
                </a>
                <a href={appStoreUrl}>
                  <img src={'/app_store_badge.svg'} />
                </a>
              </View>
            </View>
            <View style={{marginLeft: 105,marginBottom:40}}>
              <img src={'/iphonexbg.png'} />
              <View style={{position: 'absolute', left: 43.5, top: 42.1}}>
                <img src={'/iphonex.png'} />
              </View>
              <img
                src={'/unthinkable.svg'}
                style={{
                  alignSelf: 'flex-end',
                  marginTop: 16,
                }}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
}
