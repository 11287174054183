import React from 'react';
import TermsConditions from './webScreen/Terms&Conditions';
import PrivacyPolicy from './webScreen/PrivacyPolicy';
import LandingScreen from './webScreen/LandingScreen';

let screens = [
  {
    'terms-conditions': TermsConditions,
    'privacy-policy': PrivacyPolicy,
  },
];
export default class App extends React.Component {
  render() {
    let Path = window.location.pathname;
    let isPath = false;
    Path = Path.slice(1);
    screens.find((item) => {
      if (item[Path]) {
        Path = item[Path];
        isPath = true;
      }
    });
    if (!isPath) {
      return (
        <TermsConditions/>
      );
    }
    return <Path />;
  }
}
