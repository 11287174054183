import React from 'react';
import {ScrollView, Text} from '@applane/react-core-components';
class Policy extends React.Component {
  constructor(props) {
    super(props);
  }
  //Policy.js file is Created because Terms&Conditions.js & PrivacyPolicy.js Both Are rendring Same Content by Kawal - on 19-05-2021
  render() {
    return (
      <ScrollView style={{margin: 20}}>
        <Text
          style={{
            alignSelf: 'center',
            fontWeight: 'bold',
            paddingBottom: 20,
            fontSize: 20,
          }}>
          {this.props.name}
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          1. Disclaimer:
        </Text>
        <Text style={{paddingBottom: 20}}>
          By accepting the customer terms and conditions, you agree to the terms
          of this privacy policy. If you do not agree with the terms and
          conditions of our Privacy Policy, including collection or use of your
          information, please do not use or access mobile application. Our
          privacy policy is subject to change. To make sure you are aware of any
          changes, please review this policy periodically
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          2. Summary & Scope
        </Text>
        <Text style={{paddingBottom: 20}}>
          Privacy policy shall form an integral part of terms of the use of this
          mobile application. This Privacy policy entails the type of
          information collected by Raga (‘we’, ‘us’, ‘our’ & ‘Raga’) and the
          usage of the said information. This Privacy Policy does not apply to
          the practices of third parties that we do not own or control.
          Additionally, you may encounter "cookies" or other similar devices on
          few pages of our Mobile application that are placed by third parties.
          We do not control the use of cookies by third parties. If you do not
          want information to be collected through the use of cookies, your
          browser allows you to deny or accept the use of cookies.
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          3. Collection of Information
        </Text>
        <Text style={{paddingBottom: 20}}>
          You may be asked to provide your personal information anytime you
          access the mobile application or visit the mobile application.
          “Personal information” is data that can be used to identify or contact
          a single person. Example: Name including first and last name, Email
          address including alternate email address, mobile/phone/contact
          number, Demographic information like age, gender, education,
          occupation, address etc. and information about the page/links you
          visit on the mobile application etc. You may be asked to provide your
          personal information anytime you visit the mobile application.
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          4. How do we collect this Information?
        </Text>
        <Text style={{paddingBottom: 20}}>
          Some illustrative examples how you may provide personal information to
          us or how we may collect this information:
          <Text style={{marginLeft: 10}}>
            • directly from you when you provide it to us or Contacting us for
            further information; (e.g. by mail, email or telephone or mobile
            application or)
          </Text>
          <Text style={{marginLeft: 10}}>
            • When you give us the permission to obtain it
          </Text>
          <Text style={{marginLeft: 10}}>
            • Clickstream data and interfaces
          </Text>
          <Text style={{marginLeft: 10}}>
            • mobile application may automatically collect and store some
            information from your mobile device including but m not limited to:
          </Text>
          <Text style={{marginLeft: 20}}>
            i. Your preferred language and country site (if applicable),
            manufacturer and model of your mobile device, mobile operating
            system, type of mobile internet browsers you are using, geolocation;
          </Text>
          <Text style={{marginLeft: 20}}>
            ii. Information about how you interact with the Mobile Application
            and any of our web sites to which the Mobile Application connects,
            such as how many times you use a specific part of the Mobile
            Application over a given time period, the amount of time you spend
            using the Mobile Application, how often you use the Mobile
            Application, actions you take in the Mobile Application and how you
            engage with the Mobile Application
          </Text>
          <Text style={{marginLeft: 20}}>
            iii. Data from SMS/ text messages upon receiving Device permissions
            for the purposes of (i) issuing and receiving one time passwords and
            other device verification, and (ii) automatically filling
            verification details during financial transactions, either through
            us or a third-party service provider, in accordance with applicable
            law. We do not share or transfer SMS/ text message data to any third
            party other than as provided under this Privacy Policy.
          </Text>
          <Text style={{marginLeft: 20}}>
            iv. Information to allow us to personalize the services and content
            available through the Mobile Application
          </Text>
          <Text style={{marginLeft: 10}}>
            • Mobile application tracking & cookies- We may use automated
            technologies including the use of web server logs to collect IP
            addresses, device details, cookies.
          </Text>
          <Text style={{marginLeft: 10}}>
            • Marketing Materials - Interfaces like seminars, events, e-mails
            campaigns etc. You may choose to unsubscribe anytime by contacting
            at the e-mail address provided herein below.
          </Text>
          <Text style={{marginLeft: 10}}>
            • Filling out contact forms and e-mail links
          </Text>
          <Text style={{marginLeft: 10}}>
            • Registering for events, promotions, contests, surveys and
            conferences
          </Text>
          <Text style={{marginLeft: 10}}>
            • Submitting CVs, resumes or work history information
          </Text>
          <Text style={{marginLeft: 10}}>
            • Providing us with business cards or other contact information
          </Text>
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          5. Purpose of Collection of Personal data
        </Text>
        <Text style={{paddingBottom: 20}}>
          When you provide personal information to us, we may use it for any of
          the purposes described in this policy or as stated at the point of
          collection (or as obvious from the context of collection) for one or
          more of the following purposes:
          <br /> a) To provide you with information that you have requested or
          which we think may be relevant to a subject in which you have
          demonstrated an interest
          <br /> b) To initiate and complete commercial transactions with you,
          or the entity that you represent
          <br /> c) We may also use personal information for internal purposes
          such as auditing, data analysis, and research to improve our products,
          services, and customer communications
          <br /> d) To ensure the security and safe operation of our mobile
          application and underlying business infrastructure
          <br /> e) To improve customer service - Information you provide helps
          us respond to your customer service requests and support needs more
          efficiently
          <br /> f) To personalize user experience - We may use information in
          the aggregate to understand how our Users as a group use the services
          and resources provided on our Site
          <br /> g) Cookies-We use cookies on our mobile applications to
          personalize content and ads, to analyse our traffic and performance,
          to provide social media features and for the functioning of the mobile
          application.
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          6. Storage of Personal Information
        </Text>
        <Text style={{paddingBottom: 20}}>
          We will retain your personal information, given the purposes for which
          it was collected, or as required to do so by law. The compliances,
          responsibilities and obligations relating to the storage and
          destruction of personal data are as per the principles set out in
          applicable relevant legislation. If you submit any Personal
          Information relating to other people to us, you represent that you
          have the authority to do so and to permit us to use the information in
          accordance with this Privacy Policy. To correct or update any
          information you have provided, the mobile application and mobile
          application allows you to do it online. In the event of loss of access
          details, you can send an e-mail to: raga@unthinkable.co
          <br />
          <br />
          We keep your information only so long as we need it to provide Raga to
          you and fulfill the purposes described in this policy. This is also
          the case for anyone that we share your information with and who
          carries out services on our behalf. When we no longer need to use your
          information and there is no need for us to keep it to comply with our
          legal or regulatory obligations, we’ll either remove it from our
          systems or depersonalize it so that we can't identify you.
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          7. Data protection & GDPR
        </Text>
        <Text style={{paddingBottom: 20}}>
          a.) We have implemented reasonable and adequate physical, technical
          and administrative security measures to protect personal information
          from loss, misuse, alteration or destruction.
          <br />
          b.) We share information to third parties only for legitimate purposes
          such as our service providers and agents who are bound to maintain the
          confidentiality of personal information and may not use the
          information for any unauthorized purpose. We will ensure through
          formally executed contracts that the service providers are committed
          to “same level of data protection” as applicable data protection laws
          and regulations.
          <br />
          c.) We comply with the EU General Data Protection Regulation framework
          regarding the collection, use, and retention of Personal Information
          from European Union member countries and Switzerland. We adhere to the
          GDPR Principles and Rights of notice, choice, accountability for
          onward transfer, security, data integrity and purpose limitation,
          access, and recourse, enforcement and liability. If there is any
          conflict between the terms in this privacy policy and the GDPR
          Principles and Rights, the GDPR Principles and Rights shall govern.
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          8. Your rights as a Data Subject
        </Text>
        <Text style={{paddingBottom: 20}}>
          As a data subject whose personal information we hold, you have certain
          rights. If you wish to exercise any of these rights, please
          raga@unthinkable.co or use the information supplied in the Contact us
          section below. In order to process your request, we will ask you to
          provide two valid forms of identification for verification purposes.
          Your rights are as follows:
          <br /> a. The right to be informed. <br />
          b. The right of access/review
          <br /> c. The right to rectification
          <br /> d. The right to erasure (the ‘right to be forgotten’)
          <br /> e. The right to restrict processing
          <br /> f. The right to data portability
          <br /> g. The right to object
          <br /> h. Your right to review, update, correct, and delete your
          personal information may be limited, subject to the law of your
          jurisdiction and/ or based on genuineness of your request.
        </Text>
        {/* New Policies Addded For Third Party Services by Kawal - on 18-05-2021 */}
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          9. Third Party Services
        </Text>
        <Text style={{paddingBottom: 20}}>
          While using Raga, you may enable and/ or use services, developed by a
          third party (“Third-Party Services”). Please understand that their
          privacy practices may differ from Raga. Therefore, you are responsible
          for complying with any third-party provider terms, including its
          privacy policy.
        </Text>
        <Text style={{paddingBottom: 20}}>
          Third party services used by Raga are-
          <Text style={{fontWeight: 'bold', marginLeft: 10}}>• Mixepanel-</Text>
          Raga uses MixPanel Analytic Services. By accessing or using Raga, you
          agree to be bound by MixPanel Privacy Policy. MixPanel Privacy policy
          is available at https://mixpanel.com/legal/privacy-policy/
          <Text style={{fontWeight: 'bold', marginLeft: 10}}>
            • Social Button-
          </Text>
          Raga Uses Facebook’s Social Button. By accessing or using Raga, you
          agree to be bound Facebook’s Privacy Policy
          (https://www.facebook.com/policy.php)
        </Text>
        {/* New Policies Added For Prohibited Content by Kawal - on 18-05-2021 */}
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          10. Prohibited Content
        </Text>
        <Text style={{paddingBottom: 20}}>
          The following are inconsistent with Raga’s intent and guidelines. Such
          content will be removed and/ or reported to law enforcement agencies -{' '}
          <br /> a. Content related to Harassment and Bullying. Personal attacks
          on others, are prohibited on our platform.
          <br />
          b. Political content that requests, directs, and/or encourages Raga
          members to vote in a particular.
          <br /> c. Sexual or explicit content.
          <br /> d. Posts and conversations that encourage or incite violence or
          hate. Any post that incites hatred or advocates violence against a
          person or group based on their actual or perceived race, color,
          ethnicity, national origin, religion, gender, gender identity, age,
          veteran status, sexual orientation, disability, health condition,
          disease, or political views or affiliation.
          <br /> e. Content that promotes, encourages, or incites acts of
          international or domestic terrorism is prohibited.
          <br /> f. Child sexual abuse material is a serious crime that we
          report to law enforcement authorities.
          <br /> g. Content that violates the intellectual property rights of
          other.
          <br /> h. Content that promotes or facilitates illegal activity is
          prohibited. This includes the distribution, purchase and sale of
          illegal items.
          <br /> i. Content that depicts or promotes severe bodily injury,
          torture or mutilation of humans or animals is prohibited.
          <br /> j. Content that promotes Human Trafficking and Prostitution.
          <br /> k. Content that involves sensitive personal information (PII)
          pertaining to yourself or others.
          <br /> l. Content designed to or that could mislead, defraud, or
          otherwise harm our users is prohibited.
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          11. Our Security Policies
        </Text>
        <Text style={{paddingBottom: 20}}>
          Our information security management system (ISMS) is certified. We do
          not, however, have any control over what happens between your device
          and the boundary of our information infrastructure. You should be
          aware of the many information security risks that exist and take
          appropriate steps to safeguard your own information. We accept no
          liability in respect of breaches that occur beyond our sphere of
          control.
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          12. Cookie policy
        </Text>
        <Text style={{paddingBottom: 20}}>
          We use cookies on our sites for a number of purposes. They help us to
          provide you with a good experience when you browse our mobile
          application, and also allow us to improve our site. By continuing to
          browse the site, you are agreeing to our use of cookies. We use the
          following cookies:
          <Text style={{fontWeight: 'bold', marginLeft: 10}}>
            a. Strictly necessary cookies.
          </Text>
          These are cookies that are required for the operation of our mobile
          application. They include, for example, cookies that enable you to log
          into secure areas of our mobile application, use a shopping cart or
          make use of the e-billing services.
          <Text style={{fontWeight: 'bold', marginLeft: 10}}>
            b. Analytical/performance cookies.
          </Text>
          They allow us to recognize and count the number of visitors and to see
          how visitors move around our mobile application when they are using
          it. This helps us to improve the way our mobile application works, for
          example, by ensuring that users find what they are looking for easily.
          <Text style={{fontWeight: 'bold', marginLeft: 10}}>
            c. Functionality cookies.
          </Text>
          These are used to recognize you when you return to our mobile
          application. This enables us to personalize our content for you, greet
          you by name and remember your preferences
          <Text style={{fontWeight: 'bold', marginLeft: 10}}>
            d. Targeting cookies.
          </Text>
          These cookies record your visit to our mobile application, the pages
          you have visited and the links you have followed. We will use this
          information to make our mobile application and the advertising
          displayed on it more relevant to your interests. We may also share
          this information with third parties for this purpose.
          <br />
          <br /> You can block cookies by activating the setting on your browser
          that allows you to refuse the setting of all or some cookies. However,
          if you use your browser settings to block all cookies (including
          essential cookies) you may not be able to access all or parts of our
          site.
        </Text>
        <Text style={{fontWeight: 'bold', paddingBottom: 10}}>
          13. Contact us
        </Text>
        <Text style={{paddingBottom: 20}}>
          If you have any comments, questions or suggestions about this privacy
          policy or you want any information related with our processes for
          handling of your personal data, you can email us at
          raga@unthinkable.co
        </Text>
      </ScrollView>
    );
  }
}
export default Policy;
